.content {
  padding: 1rem;
  background: transparent;
  height: 100%;
  width: 100%;

  // background: var(--app-input-bg-rgb);

  > .imgContent {
    width: 100%;
    aspect-ratio: 1;
    border: 1px solid var(--app-text-30-color);
    display: flex;
    justify-content: center;
    align-items: center;

    > .imgItem {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  > .title {
    margin-top: 20px;
    font-size: 14px;
    color: var(--app-text-color);
  }

  > .author {
    margin-top: 10px;

    span {
      font-size: 14px;
      color: var(--app-link-color);

      &:first-child {
        padding-right: 5px;
        color: var(--app-text-color);
      }
    }
  }

  > .rateContainer {
    margin-top: 10px;
  }
}

