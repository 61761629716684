.backContainer {
  background: var(
    --app-home-gpt-bg-rgb
  ); //linear-gradient(to right, rgba(225,174,114,0.3), rgba(225,174,114,0.1));
  padding: 32px 24px;
  width: 100%;

  > .topDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    span {
      font-size: 20px;
      font-weight: 900;
    }

    > a {
      font-size: 16px;
      font-weight: 400;
      color: var(--app-home-gpt-a-rgb);
    }
  }

  > .mainItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

@media screen and (min-width: 576px) {
  .backContainer {
    > .mainItem {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media screen and (max-width: 576px) {
  .backContainer {
    > .mainItem {
      grid-template-columns: 1fr;
    }
  }
}
