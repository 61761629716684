.marketplaceHero {
  border-top: none;
  // min-height: 100px;
  margin-bottom: 1rem;

  > .heroSection {
    &.heroImageSection {
      > .heroImage {
        padding-top: 1rem;
        width: 100%;
        height: auto;
      }
    }
  }

  .skeletonDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    .activeImg {
      padding-top: 1rem;
      width: 100%;
      min-height: 100px;
      aspect-ratio: 1060/362;
    }
  }
}

// @media (max-width: 600px) {
// }

// @media (min-width: 601px) {
// }

// @media (min-width: 980px) {
// }

// @media (min-width: 1120px) {
// }

// @media (min-width: 1300px) {
// }

