.mainContainer {
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--foreground-rgb);
  width: 100%;

  > h3 {
    color: var(--app-text-color);
    font-size: 16px;
    font-weight: bold;
  }

  > .flexItem {
    height: 80px;
    align-items: center;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;

    > .indexItem {
      display: block;
      font-size: 16px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: white;
      background-color: #ff6464;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
    }

    > .imgItem {
      display: block;
      width: auto;
      height: 100%;
      aspect-ratio: 1;
      alt: 'avatar';
      object-fit: cover;
    }

    .titleItem {
      color: var(--app-text-color);
      font-size: 16px;
      // font-weight: 500;
      /* max-width: 180px; */
      padding-left: 10px;
      /* padding-right: 10px; */

      display: -webkit-box;
      overflow: hidden;
      /* word-break: break-word; */
      white-space: normal;
      word-break: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;
      line-height: 1.2;

      &:hover {
        text-decoration: underline;
      }
    }

    // > .titleSkeletonItem {
    //     padding: 20px;
    // }
  }
}

