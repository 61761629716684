.mainContainer {
  // padding: 24px;
  width: 100%;

  // ::after {
  //  content: ''; // 必须
  //  position: absolute;
  //  left: 0;
  //  bottom: 0;
  //  right: 0;
  //  width: 100%;
  //  height: 1px;
  //  background-color: #dcdcdc;
  // }

  .titleContainer {
    color: var(--app-text-color);
    // font-size: 20px;
    // font-weight: 900;

    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    white-space: normal;
    -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    line-height: 1.2;

    &:hover {
      text-decoration: underline;
    }
  }

  .pcTitleContainer {
    font-size: 20px;
    font-weight: 900;
    -webkit-line-clamp: 2;
  }

  .mobileTitleContainer {
    font-size: 18px;
    font-weight: 700;
    -webkit-line-clamp: 5;
  }

  .descContainer {
    color: var(--app-text-60-color);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;

    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    line-height: 1.2;
  }

  .timeContainer {
    color: var(--app-text-30-color);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }

  .imgContainer {
    // display: block;
    width: 100%;
    // height: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
  }

  // .imgContainer {
  //     position: relative;
  //     width: 100%;
  //     aspect-ratio: 16/9;
  //     > .image {
  //         position: absolute;
  //         height: 100%;
  //         aspect-ratio: 16/9;
  //         object-fit: contain;
  //     }
  // }
}

