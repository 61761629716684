.homeplace {
  color: rgba(0, 0, 0, 0.87);
  padding: 0 0 2rem;
  overflow-x: hidden;
  background-color: var(--app-home-bg-rgb); //rgba(241, 218, 179, 1);
  background-size: 100% auto;
  background-repeat: no-repeat;

  .homeContent {
    padding: 1rem;
    margin: 0 auto;
    max-width: 1060px;

    .leftContainer {
      background: var(--foreground-rgb);
      .loadMoreContent {
        width: 100%;
        padding: 24px;

        > .loadMore {
          background: var(--app-line-bg-rgb);
          align-items: center;
          display: flex;
          justify-content: center;
          border-radius: 10px;
          cursor: pointer;

          > .loadMoreBtn {
            padding: 10px;
            color: var(--app-text-color);
            font-size: 16px;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 600px) {
//   .homeplace {
//   }
// }

// @media screen and (max-width: 1120px) {
//   .homeplace {
//   }
// }

