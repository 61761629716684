.backContainer {
  background: var(
    --app-home-gpt-bg-rgb
  ); //linear-gradient(to right, rgba(225,174,114,0.3), rgba(225,174,114,0.1));
  padding: 32px 24px;
  width: 100%;

  > .topDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    span {
      font-size: 20px;
      font-weight: 900;
    }

    > a {
      font-size: 16px;
      font-weight: 400;
      color: var(--app-home-gpt-a-rgb);
    }
  }

  > .mainItem {
    margin: 0;
    padding: 0 -12px;

    :global {
      .next-antd-card-bordered {
        border: 0;
      }
    }

    .imgItem {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 4px solid var(--foreground-rgb);
      position: absolute;
      top: -20px;
      left: 30px;
    }

    .titleItem {
      color: var(--app-text-color);
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;

      display: -webkit-box;
      overflow: hidden;
      word-break: break-word;
      white-space: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      line-height: 1.2;

      &:hover {
        text-decoration: underline;
      }
    }

    .descItem {
      color: #999;
      font-size: 14px;
      font-weight: 400;

      display: -webkit-box;
      overflow: hidden;
      word-break: keep-all;
      white-space: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      line-height: 1.2;
    }
  }
}

